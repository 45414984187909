import { animated, useSpring } from '@react-spring/web'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './ReadProgression.css'

import arrowLeftIcon from '/images/icons/arrow-left.raw.svg'
import checkmarkIcon from '/images/icons/check.raw.svg'

export function ReadProgression({ backLink, readTime, width, hideBar, reachedEnd, layoutClassName = undefined }) {
  const style = useSpring({
    y: hideBar ? -35 : 0,
    config: { mass: 1, friction: 20, tension: 60 }
  })

  return (
    <animated.div className={cx(styles.component, layoutClassName)} {...{ style }}>
      <FillableBar layoutClassName={styles.fillableBarLayout} {...{ width }} />
      <BarContent layoutClassName={styles.barContentLayout} {...{ backLink, readTime, reachedEnd }} />
    </animated.div>
  )
}

function FillableBar({ width, layoutClassName = undefined }) {
  return <animated.div style={{ width }} className={cx(styles.componentFillableBar, layoutClassName)} />
}

function BarContent({ backLink, readTime, reachedEnd, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentBarContent, layoutClassName)}>
      <div className={styles.barContentLeft}>
        <a href={backLink} data-x='link-back-to-feed' className={styles.backLink}>
          <Icon icon={arrowLeftIcon} layoutClassName={styles.iconLayout} />
        </a>
        <p className={styles.readTimeText}>{readTime}</p>
      </div>
      <CheckMarkIcon layoutClassName={styles.checkMarkLayout} {...{ reachedEnd }} />
    </div>
  )
}

function CheckMarkIcon({ reachedEnd, layoutClassName = undefined }) {
  const style = useSpring({
    backgroundColor: reachedEnd ? 'rgba(255, 255 , 255, 1)' : 'rgba(255, 255 ,255, 0)',
    config: { mass: 1, friction: 5, tension: 60 }
  })

  return (
    <animated.div
      className={cx(
        styles.componentCheckMarkIcon,
        reachedEnd && styles.checkMarkReachedEnd,
        layoutClassName
      )}
      {...{ style }}
    >
      <Icon icon={checkmarkIcon} layoutClassName={styles.checkMarkIconLayout} />
    </animated.div>
  )
}
