import { useMergeRefs, FloatingOverlay as FloatingOverlayBase } from '@floating-ui/react'
import { animated, useSpring } from '@react-spring/web'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'

import { useTranslate } from '/machinery/I18n'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { useScrollDirection } from '/machinery/useScrollDirection'
import { useEvent } from '/machinery/useEvent'
import { useScrollbarWidth } from '/machinery/useScrollbarWidth'

import { useItemUrl } from '/features/regionArticles/useItemUrl'
import { NudgingChevronPage } from '/features/buildingBlocks/NudgingChevron'
import { ArticleHeroBordered, ArticleHeroDefault, ArticleHeroMemberOffer, ArticleHeroSingleSnackable, ArticleHeroSnackables } from '/features/article/buildingBlocks/ArticleHero'
import { ArticleIntroBordered, ArticleIntroDefault, ArticleIntroDefaultBlack, ArticleIntroGedicht } from '/features/article/buildingBlocks/ArticleIntro'
import { NextArticleUitgelezen } from '/features/article/buildingBlocks/NextArticleUitgelezen'

import mediaStyles from '/cssGlobal/media.css'
import styles from './NextArticle.css'

export function NextArticle({ doc, issues, layoutClassName = undefined }) {
  switch (doc.metadata.template) {
    case 'research':
    case 'single_snackable':
    case 'single_snackable_content':
      return <NextArticleBordered {...{ doc, layoutClassName }} />
    case 'kaliber-snackables':
      return <NextArticleSnackables {...{ doc, layoutClassName }} />
    case 'gedicht':
      return <NextArticleGedicht {...{ doc, layoutClassName }} />
    case 'kaliber-member_offer':
      return <NextArticleMemberOffer {...{ doc, layoutClassName }} />
    case 'kaliber-uitgelezen':
      return <NextArticleUitgelezen {...{ doc, issues, layoutClassName }} />
    case 'default':
    default:
      return <NextArticleDefault {...{ doc, layoutClassName }} />
  }
}

function NextArticleDefault({ doc, layoutClassName }) {
  const { content, metadata: { rubric, tags } } = doc
  const { hero } = content

  return (
    <NextArticleContainer
      renderHeroComponent={({ layoutClassName }) =>
        <ArticleHeroDefault {...{ hero, layoutClassName }} />
      }
      renderIntroComponent={({ layoutClassName, url }) =>
        <ArticleIntroDefault isNextArticleIntro rubric={rubric.name} {...{ tags, hero, url, layoutClassName }} />
      }
      {...{ doc, layoutClassName }}
    />
  )
}

function NextArticleMemberOffer({ doc, layoutClassName }) {
  const { content, metadata: { rubric } } = doc
  const { hero, articles } = content
  const { title } = hero

  return (
    <NextArticleContainer
      renderIntroComponent={({ layoutClassName }) =>
        <ArticleHeroMemberOffer rubric={rubric.name} {...{ title, articles, layoutClassName }} />
      }
      {...{ doc, layoutClassName }}
    />
  )
}

function NextArticleResearch({ doc, layoutClassName }) {
  const { content, metadata: { rubric } } = doc
  const { hero } = content

  return (
    <NextArticleContainer
      renderHeroComponent={({ layoutClassName }) =>
        <ArticleHeroDefault {...{ hero, layoutClassName }} />
      }
      renderIntroComponent={({ layoutClassName, url }) =>
        <ArticleIntroDefaultBlack isNextArticleIntro rubric={rubric.name} {...{ hero, layoutClassName, url }} />
      }
      {...{ doc, layoutClassName }}
    />
  )
}

function NextArticleSingleSnackable({ doc, layoutClassName }) {
  const { content, metadata: { rubric } } = doc
  const { hero } = content
  const { title, image } = hero

  return (
    <NextArticleContainer
      renderIntroComponent={({ layoutClassName }) =>
        <ArticleHeroSingleSnackable heroImage={image} rubric={rubric.name} {...{ title, layoutClassName }} />
      }
      {...{ doc, layoutClassName }}
    />
  )
}

function NextArticleSnackables({ doc, layoutClassName }) {
  const { content, metadata: { rubric } } = doc
  const { hero, articles } = content
  const { title } = hero

  return (
    <NextArticleContainer
      renderIntroComponent={({ layoutClassName }) =>
        <ArticleHeroSnackables rubric={rubric.name} {...{ title, articles, layoutClassName }} />
      }
      {...{ doc, layoutClassName }}
    />
  )
}

function NextArticleBordered({ doc, layoutClassName }) {
  const { content, metadata: { tags, rubric } } = doc
  const { hero } = content

  return (
    <NextArticleContainer
      renderHeroComponent={({ layoutClassName }) =>
        <ArticleHeroBordered {...{ hero, layoutClassName }} />
      }
      renderIntroComponent={({ layoutClassName }) =>
        <ArticleIntroBordered isNextArticleIntro rubric={rubric.name} {...{ hero, tags, layoutClassName }} />
      }
      {...{ doc, layoutClassName }}
    />
  )
}

function NextArticleGedicht({ doc, layoutClassName }) {
  const { content, metadata: { rubric } } = doc
  const { hero } = content

  return (
    <NextArticleContainer
      renderHeroComponent={({ layoutClassName }) =>
        <ArticleHeroDefault {...{ hero, layoutClassName }} />
      }
      renderIntroComponent={({ layoutClassName, url }) =>
        <ArticleIntroGedicht isNextArticleIntro rubric={rubric.name} {...{ hero, layoutClassName, url }} />
      }
      {...{ doc, layoutClassName }}
    />
  )
}

function NextArticleContainer({ doc, renderIntroComponent, renderHeroComponent = undefined, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const sectionRef = React.useRef(null)
  const [scrollProgression, setScrollProgression] = React.useState(0)
  const url = useItemUrl(doc)

  const [triggerNextArticle, setTriggerNextArticle] = React.useState(false)

  const { heroImageStyle } = useHeroImageStyleAndTrigger({ scrollProgression, triggerNextArticle, setTriggerNextArticle, url })

  const scrollProgressionRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.bottom(), scrollParent: triggers.bottom() },
    onChange(progression) { setScrollProgression(progression) }
  })

  const onTriggerNextArticleEvent = useEvent(handleTriggerNextArticle)

  useScrollDirection({
    deltaThreshold: 50,
    scrollThreshold: 50,
    callback({ direction }) {
      if (scrollProgression > 0.8 && direction === 1) onTriggerNextArticleEvent()
    }
  })

  return (
    <section ref={useMergeRefs([scrollProgressionRef, sectionRef])} data-x='next-article' className={cx(styles.componentContainer, layoutClassName)}>
      <SpeculationRules {...{ url }} />

      <NextArticleLink onClick={handleTriggerNextArticle} layoutClassName={styles.nextArticleLinkLayout} />

      <NextArticleHero
        onClick={handleTriggerNextArticle}
        style={heroImageStyle}
        layoutClassName={styles.nextArticleHeroLayout}
        {...{ renderHeroComponent, renderIntroComponent, scrollProgression, triggerNextArticle, url }}
      />

      {isViewportMd && <FloatingOverlayBase lockScroll={triggerNextArticle} className={styles.overlay} />}
    </section>
  )

  function handleTriggerNextArticle() {
    const top = sectionRef.current?.offsetTop + sectionRef.current?.clientHeight + 100

    setTriggerNextArticle(true)

    window.scrollTo({ top, behavior: 'smooth' })
  }
}

function NextArticleHero({ onClick, renderHeroComponent, renderIntroComponent, scrollProgression, triggerNextArticle, url, style, layoutClassName = undefined }) {
  const scrollbarWidth = useScrollbarWidth()

  return (
    <div
      style={{ '--scrollbar-width': scrollbarWidth + 'px', height: `${easeIn(scrollProgression) * 100}%` }}
      className={cx(styles.componentHero, layoutClassName)}
    >
      <animated.div className={cx(styles.nextArticleHeroImageContainer, triggerNextArticle && styles.triggerNextArticle)} {...{ style }}>
        <button className={styles.nextArticleLinkHeroButton} {...{ onClick }} />
        {renderHeroComponent && renderHeroComponent({ layoutClassName: styles.articleHeroLayout })}
      </animated.div>
      <div className={cx(styles.nextArticleHeadingContainer, triggerNextArticle && styles.triggerNextArticle)}>
        {renderIntroComponent({ url, layoutClassName: styles.articleIntroLayout })}
      </div>
    </div>
  )
}

function NextArticleLink({ onClick, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentLink, layoutClassName)}>
      <button type='button' data-x='click-to-next-article' className={styles.nextArticleLink} {...{ onClick }}>
        <NudgingChevronPage />
        <span>{__`scroll-or-click-for-nex-article`}</span>
      </button>
    </div>
  )
}

function SpeculationRules({ url }) {
  return (
    <script type="speculationrules" dangerouslySetInnerHTML={{
      __html: JSON.stringify(
        { 'prefetch': [{ 'source': 'list', 'urls': [url] }] }
      )
    }} />
  )
}

function useHeroImageStyleAndTrigger({ scrollProgression, triggerNextArticle, setTriggerNextArticle, url }) {
  const [triggeredNextArticle, setTriggeredNextArticle] = React.useState(false)

  React.useEffect(
    () => {
      if (triggerNextArticle) {
        setTriggeredNextArticle(true)
      }
    },
    [triggerNextArticle]
  )

  const heroImageStyle = useSpring({
    y: triggeredNextArticle
      ? '0%'
      : `${3 - (easeOutQuad(scrollProgression) / 20 * 100)}%`,
    scale: triggeredNextArticle
      ? 1
      : 1.15 - easeOutQuad(scrollProgression) / 20,
    config: { mass: 1.5, friction: 20, tension: 90 },
    onRest: { y: handleGoToNextArticle }
  })

  return { heroImageStyle }

  function handleGoToNextArticle() {
    if (triggerNextArticle) {
      setTriggerNextArticle(false)
      window.location.href = url
    }
  }
}

function easeIn(x) {
  return x * x + x
}

function easeOutQuad(x) {
  return 1 - (1 - x) * (1 - x)
}
