import { useIsomorphicLayoutEffect } from '@react-spring/web'

export const scrollbarMeasuringReference = 'scrollbar-measuring-reference'

export function useScrollbarWidth() {
  const [scrollbarWidth, setScrollbarWidth] = React.useState(15)

  useIsomorphicLayoutEffect(
    () => {
      const element = document.getElementById(scrollbarMeasuringReference)
      setScrollbarWidth(element.offsetWidth - element.clientWidth)
    },
    []
  )

  return scrollbarWidth
}
